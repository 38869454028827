import { Alert, Container, Grid } from '@mui/material';
import RetailMediaCreditsTopUp from '@/components/RetailMedia/Credits/TopUp';
import { Navigate, useParams } from 'react-router-dom';
import api from '@/utils/api';
import { useCallback, useEffect, useState } from 'react';
import { RetailMediaAccount } from '@/types/user';
import { LoadingButton } from '@mui/lab';

export default function RetailMediaVendorCreditsPage() {
  const { uuid } = useParams();

  const [vendor, setVendor] = useState<RetailMediaAccount | null>(null);
  const [isPending, setIsPending] = useState<boolean>(true);

  const fetchVendor = useCallback(async () => {
    setIsPending(true);
    setVendor(null);

    try {
      const { data } = await api.get(`/retail-media/vendor/${uuid}`);

      setVendor(data);
    } catch (e) {
    } finally {
      setIsPending(false);
    }
  }, [uuid]);

  useEffect(() => {
    fetchVendor();
  }, [fetchVendor]);

  if (!uuid) {
    return <Navigate to="/404"></Navigate>;
  }

  return (
    <Container>
      {!isPending ? (
        vendor?.retailMediaEnabled ? (
          <RetailMediaCreditsTopUp
            vendor={{ ...vendor, uuid }}
            minimumAmount={vendor.retailMediaMinimumDeposit}
          />
        ) : (
          <Alert severity="error">
            This vendor was not found or does not have retail media enabled.
          </Alert>
        )
      ) : (
        <Grid container component="main" sx={{ height: '100vh' }}>
          <Grid item xs={12} alignItems="center" justifyContent="center">
            <LoadingButton
              size="large"
              fullWidth
              sx={{ height: '100%' }}
              loading={true}
              disabled={true}
            />
          </Grid>
        </Grid>
      )}
    </Container>
  );
}
