import { ListSubheader, List, Button, ListItem } from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';

import { SubMenuWrapper } from '.';
import { useSidebar } from '@/contexts/SidebarContext';
import {
  AssessmentTwoTone,
  ProductionQuantityLimitsTwoTone,
  ShoppingBagTwoTone,
  WarehouseTwoTone,
} from '@mui/icons-material';
import { useAuth } from '@/contexts/AuthContext';

export default function FbcMenu() {
  const { user } = useAuth();
  const { closeSidebar } = useSidebar();

  if (!user?.seller.fbcSellerCode) {
    return;
  }

  return (
    <List
      component="div"
      subheader={
        <ListSubheader component="div" disableSticky>
          Fulfilled by Catch
        </ListSubheader>
      }
    >
      <SubMenuWrapper>
        <List component="div">
          <ListItem component="div">
            <Button
              component={RouterLink}
              onClick={closeSidebar}
              to="/fbc/skus"
              startIcon={<ProductionQuantityLimitsTwoTone />}
            >
              SKUs
            </Button>
          </ListItem>
          <ListItem component="div">
            <Button
              component={RouterLink}
              onClick={closeSidebar}
              to="/fbc/po"
              startIcon={<WarehouseTwoTone />}
            >
              POs
            </Button>
          </ListItem>
          <ListItem component="div">
            <Button
              component={RouterLink}
              onClick={closeSidebar}
              to="/fbc/orders"
              startIcon={<ShoppingBagTwoTone />}
            >
              Orders
            </Button>
          </ListItem>
          <ListItem component="div">
            <Button
              component={RouterLink}
              onClick={closeSidebar}
              to="/fbc/reports"
              startIcon={<AssessmentTwoTone />}
            >
              Reports
            </Button>
          </ListItem>
        </List>
      </SubMenuWrapper>
    </List>
  );
}
