import { FormConfig } from '@/components/FilteredList/FilterInput';
import {
  ErrorResponse,
  FbcSkuInventory,
  ListFbcSkuInventoryRequest,
  Operator,
  PaginatedFbcSkuInventoryResponse,
  Warehouses,
} from '@/types/warehouse';
import {
  Pagination,
  Sort,
  TableColumnConfig,
} from '@/components/FilteredList/ItemTable';
import { Tooltip, Typography } from '@mui/material';
import { unixToFormattedDate } from '@/utils/formatters';
import { removeEmptyValues } from '@/utils/utils';
import api from '@/utils/api';
import FilteredList from '@/components/FilteredList';

interface SkuInventoryFilter {
  skuId: string;
  skuName: string;
  warehouse: Warehouses;
  quantityUnallocatedOperator: string;
  quantityUnallocated: number;
  quantityOnPoOperator: string;
  quantityOnPo: number;
}

export default function InventoryTable() {
  const filterForm: FormConfig = [
    [
      {
        type: 'text',
        key: 'skuId',
        label: 'SKU ID',
      },
      {
        type: 'text',
        key: 'skuName',
        label: 'SKU Name',
      },
      {
        type: 'select',
        key: 'warehouse',
        label: 'Warehouse',
        options: Object.values(Warehouses).map((option) => ({
          label: option,
          value: option,
        })),
      },
    ],
    [
      {
        type: 'optionAndText',
        key: 'quantityUnallocated',
        label: 'Quantity Unallocated',
        inputType: 'number',
        optionKey: 'quantityUnallocatedOperator',
        options: [
          { label: 'Less than', value: Operator.LESS_THAN },
          { label: 'Greater than', value: Operator.GREATER_THAN },
          { label: 'Equals to', value: Operator.EQUALS },
        ],
      },
      {
        type: 'optionAndText',
        key: 'quantityOnPo',
        label: 'Quantity on PO',
        inputType: 'number',
        optionKey: 'quantityOnPoOperator',
        options: [
          { label: 'Less than', value: Operator.LESS_THAN },
          { label: 'Greater than', value: Operator.GREATER_THAN },
          { label: 'Equals to', value: Operator.EQUALS },
        ],
      },
    ],
  ];
  const columns: TableColumnConfig<FbcSkuInventory>[] = [
    {
      id: 'skuId',
      label: 'SKU ID',
      render: (skuInventory) => (
        <Typography noWrap>{skuInventory.sku.id}</Typography>
      ),
      sortable: false,
    },
    {
      id: 'skuName',
      label: 'SKU Name',
      render: (skuInventory) => (
        <Typography noWrap maxWidth={200}>
          {skuInventory.sku.name}
        </Typography>
      ),
      sortable: false,
    },
    {
      id: 'warehouse',
      label: 'Warehouse',
      render: (skuInventory) => skuInventory.warehouse,
      sortable: true,
    },
    {
      id: 'quantityUnallocated',
      label: (
        <Tooltip
          title="Quantity available for sale. It is the quantity in Catch warehouse minus the quantity already allocated to orders."
          placement="top"
        >
          <span>Quantity Unallocated</span>
        </Tooltip>
      ),
      render: (skuInventory) => skuInventory.quantityUnallocated,
      sortable: true,
    },
    {
      id: 'quantityOnSale',
      label: (
        <Tooltip title="Quantity already allocated to orders." placement="top">
          <span>Quantity on Sale</span>
        </Tooltip>
      ),
      render: (skuInventory) => skuInventory.quantityOnSale,
      sortable: true,
    },
    {
      id: 'quantityOnHand',
      label: (
        <Tooltip title="Total quantity in Catch warehouse." placement="top">
          <span>Quantity in Warehouse</span>
        </Tooltip>
      ),
      render: (skuInventory) => skuInventory.quantityOnHand,
      sortable: true,
    },
    {
      id: 'quantityOnPo',
      label: (
        <Tooltip
          title="Quantity on Purchase Order arriving at Catch warehouse soon."
          placement="top"
        >
          <span>Quantity on PO</span>
        </Tooltip>
      ),
      render: (skuInventory) => skuInventory.quantityOnPo,
      sortable: true,
    },
    {
      id: 'expiryDate',
      label: 'Expiry Date',
      render: (skuInventory) =>
        skuInventory.expiryDate
          ? unixToFormattedDate(skuInventory.expiryDate, true)
          : '-',
      sortable: true,
    },
    {
      id: 'oversized',
      label: 'Oversized',
      render: (skuInventory) => (skuInventory.oversized ? 'Yes' : 'No'),
      sortable: true,
    },
  ];
  const rowsPerPageOptions = [10, 25, 50, 100];
  const loadItems = async (
    filter: SkuInventoryFilter,
    pagination: Pagination,
    sort: Sort,
  ) => {
    const requestParams = {
      skuId: filter.skuId ?? null,
      skuName: filter.skuName ?? null,
      warehouse: filter.warehouse ?? null,
      quantityUnallocated: filter.quantityUnallocated ?? null,
      quantityUnallocatedOperator: filter.quantityUnallocatedOperator ?? null,
      quantityOnPo: filter.quantityOnPo ?? null,
      quantityOnPoOperator: filter.quantityOnPoOperator ?? null,
      page: pagination.page,
      limit: pagination.limit,
      sortBy: sort.key,
      sortDirection: sort.direction,
    } as ListFbcSkuInventoryRequest;

    const response = await api.get<
      PaginatedFbcSkuInventoryResponse | ErrorResponse
    >('/warehouse/fbc/skus/inventory', {
      params: removeEmptyValues(requestParams),
    });

    if ('errors' in response.data) {
      throw new Error(response.data.errors[0]?.message ?? '');
    }

    return {
      items: response.data.soh,
      pagination: {
        page: pagination.page,
        limit: pagination.limit,
        count: response.data.total,
      },
      sort: {
        key: response.data.sort.key,
        direction: response.data.sort.direction,
      },
    } as {
      items: FbcSkuInventory[];
      pagination: Pagination;
      sort: Sort;
    };
  };

  return (
    <FilteredList
      filterForm={filterForm}
      columns={columns}
      rowsPerPageOptions={rowsPerPageOptions}
      defaultSortKey="warehouse"
      loadItems={loadItems}
      getItemId={(skuInventory) =>
        `${skuInventory.sku.sellerCode}-${skuInventory.sku.id}-${skuInventory.warehouse}`
      }
    />
  );
}
