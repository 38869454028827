import { Alert, AlertTitle, Container } from '@mui/material';
import Footer from '@/components/Footer';
import { useState, useEffect, useCallback } from 'react';
import { api } from '@/utils/api';
import { LoadingButton } from '@mui/lab';
import { AxiosError } from 'axios';

export default function RetailMediaDashboardPage() {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [iframeUrl, setIframeUrl] = useState<string | null>(null);

  const fetchSsoUrl = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await api.get<{ url: string }>('/retail-media/sso');

      setIframeUrl(response.data.url);
    } catch (e) {
      setError(
        e instanceof AxiosError
          ? e.response?.data?.message
          : e instanceof Error
            ? e.message
            : `${e}`,
      );
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchSsoUrl();
  }, [fetchSsoUrl]);

  return (
    <>
      <title>Retail Media Dashboard</title>
      <Container
        maxWidth="lg"
        sx={{ height: 'calc(100vh - 175px)', minHeight: '400px', mt: 2 }}
      >
        {loading ? (
          <LoadingButton size="large" loading={true} disabled={true} />
        ) : (
          <>
            {error !== null && (
              <Alert severity="error" onClose={() => setError(null)}>
                <AlertTitle>Error</AlertTitle>
                Failed to load dashboard: {error || 'something went wrong'}.
                Please try again.
              </Alert>
            )}
            {iframeUrl !== null && (
              <iframe
                src={iframeUrl}
                style={{
                  width: '100%',
                  height: '100%',
                  border: 0,
                }}
                title="Retail Media Dashboard"
              ></iframe>
            )}
          </>
        )}
      </Container>
      <Footer />
    </>
  );
}
