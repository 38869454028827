import PageTitleWrapper from '@/components/PageTitleWrapper';
import { Container, Grid, Typography } from '@mui/material';
import Footer from '@/components/Footer';
import RetailMediaCreditsBalance from '@/components/RetailMedia/Credits/Balance';
import RetailMediaCreditsTopUp from '@/components/RetailMedia/Credits/TopUp';
import { useAuth } from '@/contexts/AuthContext';

export default function RetailMediaCreditsPage() {
  const { user } = useAuth();
  const MIN_TOPUP_AMOUNT = user?.seller.retailMediaMinimumDeposit ?? 100;

  return (
    <>
      <title>Retail Media Credits</title>
      <PageTitleWrapper>
        <Typography variant="h3" component="h3" gutterBottom>
          Retail Media Credits
        </Typography>
        <Typography variant="subtitle2">
          View your retail media credits.
        </Typography>
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <RetailMediaCreditsBalance />
          </Grid>
          <Grid item xs={12}>
            <RetailMediaCreditsTopUp minimumAmount={MIN_TOPUP_AMOUNT} />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}
